.btn {
    margin-left: 2px;
    margin-right: 2px;
}

.big-icons {
    font-size: 2em;
    padding-bottom: 1em;
}

.message {
    padding-bottom: 1em;
}
