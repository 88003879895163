.modal-body {
    margin: 16px;
}

.custom-switch {
    padding-top: 8px;
    float: right;
}

.validation-error {
    margin: 4px;
    color: #DD3C57;
}

.input.form-control {
    margin-bottom: 8px;
}

.input.form-select {
    margin-bottom: 8px;
}
