.codeBlock {
    margin-top: 10px;
    margin-bottom: 20px;
}

.codeBlock code {
    font-family: "Fira Code", monospace;
    font-size: 0.9em ;
}

.btn-copy-code {
    position: absolute; top:0; right:0;
    color: #ffffffaa;
    z-index: 100;
}

.btn-copy-code:hover {
    color: #ffffff;
}

.tab-content {
    position: relative;
}

.code-tab {
    color: #242838;
    background-color: #ffffff;
}
