.toc {
  margin-top: 64px;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 64px;
  text-align: left;
}

@media only screen and (min-width: 800px) {
  .toc {
    top: 0;
    bottom: 0;
    position: fixed;
    overflow: hidden;
    overflow-y: scroll;
    top: 0;
    left: 0;
    width: 20%;
    border-right: solid 1px #afb0b5;
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 800px) {
  .toc {
    display: none;
  }
}

.toc .items {
  padding-bottom: 16px;
  list-style-type: none;
}

.toc a {
  color: #242838;
  font-weight: 600;
  text-decoration: none;
}

.toc a:hover {
  color: #8c8e96;
  text-decoration: none;
  font-weight: 600;
}

.toc .heading {
  padding-top: 28px;
  margin-bottom: 20px;
  width: 50%;
}

.toc .H1 {
  margin-left: 0px;
  margin-bottom: 16px;
}

.toc .H2 {
  margin-left: 32px;
  margin-bottom: 12px;
}

.toc .H3 {
  margin-left: 64px;
  margin-bottom: 8px;
  font-size: small;
}

@media only screen and (min-width: 800px) {
  .documentation-content {
    margin-left: 20%;
    padding-left: 32px;
    padding-right: 32px;
  }
}

.documentation-content {
  text-align: left;
}

.documentation-content h1 {
  padding-top: 20px;
  width: 50%;
}

.documentation-content h2 {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 50%;
}

.documentation-content h3 {
  width: 50%;
  font-size: larger;
}

.documentation-content .doc-section {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 700px) {
  .documentation-content .doc-section {
    display: flex;
    flex-direction: row;
  }
}

.documentation-content .doc-description {
  line-height: 1.7em;
  flex: 1;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: justify;
  padding-right: 32px;
}

.documentation-content .doc-example {
  flex: 1;
  font-weight: 600;
}

.keyword {
  color: #ff9020;
  font-weight: bold;
}

.example {
  color: #dd3c57;
  font-weight: bold;
}
