.footer-content {
    border-top: 1px solid #E7E7E7;
    text-align: center;
    padding: 20px;
    position: fixed;
    left: 0;
    bottom: 0;
    height: 60px;
    width: 100%;
    background-color: white;
}

.footer-phantom {
    display: block;
    padding: 20px;
    height: 60px;
    width: 100%;
}

.footer-link {
    color: #242838;
    text-decoration-line: none;
}

.footer-link:hover {
    color: #DD3C57;
    text-decoration-line: none;
}
