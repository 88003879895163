.App {
  text-align: center;
  color: #242838;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #242838;
}

.text-muted {
  font-size: 14px;
}

.description {
  font-size: 20px;
  margin-left: 20%;
  margin-right: 20%;
}

.space40 {
  margin-bottom: 40px;
}

.email-input {
  margin-left: 20%;
  margin-right: 20%;
  max-width: 60%;
}

.App-link {
  color: #61dafb;
}

.App-container {
  outline: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

.panel {
  margin-top: 42px;
}

.App-body {
  margin-top: 70px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 60px;
  outline: none;
}

.btn-add-item {
  background-color: #A1C658 !important;
  color: #FFF !important;
  float: right;
  margin-bottom: 8px;
}

.btn-confirm {
  background-color: #A1C658 !important;
  color: #FFF !important;
  float: right;
  margin-bottom: 8px;
}

.btn-cancel {
  background-color: #DD3C57 !important;
  color: #FFF !important;
  float: right;
  margin-bottom: 8px;
}

.bg-primary {
  background-color: #FF9020 !important;
  color: #FFF !important;
}

.bg-secondary {
  background-color: #63C1C6 !important;
  color: #FFF !important;
}

.bg-danger {
  background-color: #DD3C57 !important;
  color: #FFF !important;
}

.bg-info {
  background-color: #242838 !important;
  color: #FFF !important;
  margin-right: 5px;
}

.btn-action {
  background-color: #242838 !important;
  color: #FFF !important;
}

a {
  color: #63C1C6;
}
