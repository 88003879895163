.tableHeader {
    margin-top: 20px;
    margin-bottom: 20px;
}

.listingTable td {
    vertical-align: middle;
}

.listingTable thead {
    height: 50px;
}

.listingTable thead th {
    vertical-align: middle;
}

.listingTable .tableFilter {
    background-color: #F6F6F7;
}

.listingTable .tableFilter td{
    padding: 12px;
}

td.rowButtons {
    text-align: right;
}

td.text {
    text-align: left;
}


.table-icon {
    margin-left: 10px;
}

.table-icon:hover {
    color: #8C8E96;
}

.tooltip-inner {
    background-color: #242838;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #242838;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: #242838;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: #242838;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: #242838;
}
