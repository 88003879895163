.modal-body {
    margin: 16px;
}

.custom-switch {
    padding-top: 8px;
    float: right;
}

.validation-error {
    margin: 4px;
    color: #DD3C57;
}

.input-group-text {
    padding-top: 0;
    padding-bottom: 0;
}

.form-check-input:checked {
    background-color: #242838;
    border-color: #242838;
}

.detail-heading {
    font-weight: bold;
}

.subscription-detail {
    font-size: small;
}
