@import url(https://cdn.jsdelivr.net/gh/tonsky/FiraCode@1.206/distr/fira_code.css);
.App {
  text-align: center;
  color: #242838;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #242838;
}

.text-muted {
  font-size: 14px;
}

.description {
  font-size: 20px;
  margin-left: 20%;
  margin-right: 20%;
}

.space40 {
  margin-bottom: 40px;
}

.email-input {
  margin-left: 20%;
  margin-right: 20%;
  max-width: 60%;
}

.App-link {
  color: #61dafb;
}

.App-container {
  outline: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

.panel {
  margin-top: 42px;
}

.App-body {
  margin-top: 70px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 60px;
  outline: none;
}

.btn-add-item {
  background-color: #A1C658 !important;
  color: #FFF !important;
  float: right;
  margin-bottom: 8px;
}

.btn-confirm {
  background-color: #A1C658 !important;
  color: #FFF !important;
  float: right;
  margin-bottom: 8px;
}

.btn-cancel {
  background-color: #DD3C57 !important;
  color: #FFF !important;
  float: right;
  margin-bottom: 8px;
}

.bg-primary {
  background-color: #FF9020 !important;
  color: #FFF !important;
}

.bg-secondary {
  background-color: #63C1C6 !important;
  color: #FFF !important;
}

.bg-danger {
  background-color: #DD3C57 !important;
  color: #FFF !important;
}

.bg-info {
  background-color: #242838 !important;
  color: #FFF !important;
  margin-right: 5px;
}

.btn-action {
  background-color: #242838 !important;
  color: #FFF !important;
}

a {
  color: #63C1C6;
}

.modal-body {
    margin: 16px;
}

.custom-switch {
    padding-top: 8px;
    float: right;
}

.validation-error {
    margin: 4px;
    color: #DD3C57;
}

.input-group-text {
    padding-top: 0;
    padding-bottom: 0;
}

.form-check-input:checked {
    background-color: #242838;
    border-color: #242838;
}

.detail-heading {
    font-weight: bold;
}

.subscription-detail {
    font-size: small;
}

.footer-content {
    border-top: 1px solid #E7E7E7;
    text-align: center;
    padding: 20px;
    position: fixed;
    left: 0;
    bottom: 0;
    height: 60px;
    width: 100%;
    background-color: white;
}

.footer-phantom {
    display: block;
    padding: 20px;
    height: 60px;
    width: 100%;
}

.footer-link {
    color: #242838;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}

.footer-link:hover {
    color: #DD3C57;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}

.tableHeader {
    margin-top: 20px;
    margin-bottom: 20px;
}

.listingTable td {
    vertical-align: middle;
}

.listingTable thead {
    height: 50px;
}

.listingTable thead th {
    vertical-align: middle;
}

.listingTable .tableFilter {
    background-color: #F6F6F7;
}

.listingTable .tableFilter td{
    padding: 12px;
}

td.rowButtons {
    text-align: right;
}

td.text {
    text-align: left;
}


.table-icon {
    margin-left: 10px;
}

.table-icon:hover {
    color: #8C8E96;
}

.tooltip-inner {
    background-color: #242838;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #242838;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: #242838;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: #242838;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: #242838;
}

.codeBlock {
    margin-top: 10px;
    margin-bottom: 20px;
}

.codeBlock code {
    font-family: "Fira Code", monospace;
    font-size: 0.9em ;
}

.btn-copy-code {
    position: absolute; top:0; right:0;
    color: #ffffffaa;
    z-index: 100;
}

.btn-copy-code:hover {
    color: #ffffff;
}

.tab-content {
    position: relative;
}

.code-tab {
    color: #242838;
    background-color: #ffffff;
}

.toc {
  margin-top: 64px;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 64px;
  text-align: left;
}

@media only screen and (min-width: 800px) {
  .toc {
    top: 0;
    bottom: 0;
    position: fixed;
    overflow: hidden;
    overflow-y: scroll;
    top: 0;
    left: 0;
    width: 20%;
    border-right: solid 1px #afb0b5;
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 800px) {
  .toc {
    display: none;
  }
}

.toc .items {
  padding-bottom: 16px;
  list-style-type: none;
}

.toc a {
  color: #242838;
  font-weight: 600;
  text-decoration: none;
}

.toc a:hover {
  color: #8c8e96;
  text-decoration: none;
  font-weight: 600;
}

.toc .heading {
  padding-top: 28px;
  margin-bottom: 20px;
  width: 50%;
}

.toc .H1 {
  margin-left: 0px;
  margin-bottom: 16px;
}

.toc .H2 {
  margin-left: 32px;
  margin-bottom: 12px;
}

.toc .H3 {
  margin-left: 64px;
  margin-bottom: 8px;
  font-size: small;
}

@media only screen and (min-width: 800px) {
  .documentation-content {
    margin-left: 20%;
    padding-left: 32px;
    padding-right: 32px;
  }
}

.documentation-content {
  text-align: left;
}

.documentation-content h1 {
  padding-top: 20px;
  width: 50%;
}

.documentation-content h2 {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 50%;
}

.documentation-content h3 {
  width: 50%;
  font-size: larger;
}

.documentation-content .doc-section {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 700px) {
  .documentation-content .doc-section {
    display: flex;
    flex-direction: row;
  }
}

.documentation-content .doc-description {
  line-height: 1.7em;
  flex: 1 1;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: justify;
  padding-right: 32px;
}

.documentation-content .doc-example {
  flex: 1 1;
  font-weight: 600;
}

.keyword {
  color: #ff9020;
  font-weight: bold;
}

.example {
  color: #dd3c57;
  font-weight: bold;
}

.menuBar.navbar {
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,.2);
}

.modal-body {
    margin: 16px;
}

.custom-switch {
    padding-top: 8px;
    float: right;
}

.validation-error {
    margin: 4px;
    color: #DD3C57;
}

.input.form-control {
    margin-bottom: 8px;
}

.input.form-select {
    margin-bottom: 8px;
}

.btn {
    margin-left: 2px;
    margin-right: 2px;
}

.big-icons {
    font-size: 2em;
    padding-bottom: 1em;
}

.message {
    padding-bottom: 1em;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}





